import { ServiceTypesApi } from '../../api'
import { setServiceTypesLoading } from '../setServiceTypesLoading'
import { setServiceTypes } from '../setServiceTypes'

export const fetchServiceTypes = options => async dispatch => {
  dispatch(setServiceTypesLoading(true))
  if (!options.serviceType) {
    dispatch(setServiceTypes([]))
  }
  const {service, category} = options
  const api = new ServiceTypesApi()
  const serviceTypes = category
    ? await api.fetchServiceTypesByServiceAndCategory(options)
    : await api.fetchServiceTypesByService(service)

  dispatch(setServiceTypes(serviceTypes))
  dispatch(setServiceTypesLoading(false))
}
