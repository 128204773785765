import { setServices } from '../setServices'
import { ServicesApi } from '../../api'

export const fetchServices = (category, isGrouped) => async dispatch => {
  if (!category || (category && category.requiredAdOptions === 'Services')) {
    const params = {}
    console.log('isg', isGrouped)
    if (isGrouped) params.isGrouped = isGrouped

    const api = new ServicesApi()
    const services = category ? await api.fetchServicesByCategory(category.id) : await api.fetchServices(params)
    dispatch(setServices(services))
  } else {
    dispatch(setServices([]))
  }
}
