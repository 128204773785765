import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { setIsGroupPage } from '../src/modules/groups/actions'
import { getCurrentGroup } from '../src/modules/groups/selectors'
import {
  setCurrentSubCategory,
  setIsCategoryPage,
  setCategoriesAndGroups
} from '../src/modules/categories/actions'
import { getCurrentSubCategory, getParentCategory } from '../src/modules/categories/selectors'
import { CategoryHorizontalMenuMobile } from '../src/modules/categories/components'
import { deepCopy } from '../src/utils'
import { adsTypes, globalOptions } from '../src/core/constants'
import { fetchAds } from '../src/modules/ads/list/actions'
import { setBrands } from '../src/modules/ads/brands/actions'
import { AdsList } from '../src/modules/ads/list/components'
import { PanelMobile } from '../src/modules/ads/list/components/PanelMobile'
import { CategoryPagination } from '../src/modules/ads/pagination/components'
import { AdsHeaderExtended } from '../src/modules/ads/filter/Container'
import { Page, Page404 } from '../src/ui'
import { setEquipment } from '../src/modules/ads/equipment/actions'
import { fetchProducts, setProducts } from '../src/modules/ads/products/actions'
import { setServices } from '../src/modules/ads/services/actions'
import { fetchServiceTypes, setServiceTypes } from '../src/modules/ads/serviceTypes/actions'
import { getIsMobileDevice } from '../src/modules/common/selectors'
import { fetchPageMetaTags } from '../src/modules/common/actions'
import initialState from '../src/modules/redux/initialState'
import config from '../src/config/config.yaml'

class OglasiCategoryPage extends React.Component {
  static async getInitialProps ({store, req, query, isServer}) {
    let {parentCategory: parentCategoryInUrl, category: categoryInUrl, page = 1, ...restQuery} = query

    const isMobileDevice = store.getState().common.isMobileDevice

    const limit = isMobileDevice
      ? globalOptions.adsCountPerPageWithoutSidebarMobile
      : globalOptions.adsCountPerPageWithoutSidebar

    const type = adsTypes.all
    const status = 'Published'
    const sortBy = 'dateDesc'
    let formStyle = 'default'

    await store.dispatch(setCategoriesAndGroups(parentCategoryInUrl, categoryInUrl))

    const state = store.getState()

    const currentParentCategory = getParentCategory(state)
    const currentGroup = getCurrentGroup(state)
    const currentSubcategory = getCurrentSubCategory(state)

    let category, isGroupPage, isCategoryPage
    let adsRequestOptions = restQuery
      ? {...restQuery, page, type, limit, status, sortBy}
      : {page, type, limit, status, sortBy}

    let isPageExists = false

    if (currentSubcategory) {
      category = currentSubcategory

      if (category.requiredAdOptions === 'Services' && category.parent) adsRequestOptions.parentCategory = category.id
      else adsRequestOptions.category = category.id

      isGroupPage = false
      isCategoryPage = true
      isPageExists = true
      formStyle = 'basic'
      await store.dispatch(fetchPageMetaTags({ category: currentSubcategory.id }))
    } else if (!categoryInUrl && currentParentCategory) {
      adsRequestOptions.parentCategory = currentParentCategory.id
      isGroupPage = false
      isCategoryPage = true
      isPageExists = true
      await store.dispatch(fetchPageMetaTags({ category: currentParentCategory.id }))
    } else {
      category = currentGroup
      if (category) {
        adsRequestOptions.group = category.id
        isPageExists = true
        await store.dispatch(fetchPageMetaTags({ group: currentGroup.id }))
      }

      isGroupPage = true
      isCategoryPage = false
    }

    if (isPageExists) {
      const paginationParams = {
        ...store.getState().ads.pagination.params,
        currentPage: Number(page),
        adsType: type,
        countPerPage: limit
      }

      if (isCategoryPage) {
        const brand = isServer ? req.query.brand : query.brand
        if (brand && category.id && !isMobileDevice) {
          await store.dispatch(fetchProducts({brand: Number(brand), category: category.id}))
        } else {
          store.dispatch(setProducts([]))
        }
        const service = isServer ? req.query.service : query.service
        const serviceType = isServer ? req.query.serviceType : query.serviceType
        if (service && category.id && !isMobileDevice) {
          await store.dispatch(fetchServiceTypes({
            service: Number(service),
            serviceType: Number(serviceType),
            category: category.id
          }))
        } else {
          store.dispatch(setServiceTypes([]))
        }
      } else {
        store.dispatch(setCurrentSubCategory(null))
        store.dispatch(setBrands([]))
        store.dispatch(setProducts([]))
        store.dispatch(setEquipment([]))
        store.dispatch(setServices([]))
        store.dispatch(setServiceTypes([]))
      }

      await store.dispatch(fetchAds({ ...adsRequestOptions }, paginationParams))
    }

    store.dispatch(setIsGroupPage(isGroupPage))
    store.dispatch(setIsCategoryPage(isCategoryPage))

    return {
      title: category && category.title
        ? `${config.pages.category.title} - ${category.title} | ${config.pages.defaultTitle}`
        : isPageExists
          ? `${config.pages.category.title} | ${config.pages.defaultTitle}`
          : config.pages.notFoundTitle,
      categoryTitle: category && category.title ? category.title : '',
      isPageExists,
      layoutStyle: 'no-sidebar',
      formStyle,
      state: OglasiCategoryPage.getPageState(store, isPageExists)
    }
  }

  static getPageState (store, isPageExists = false) {
    const state = deepCopy(initialState)
    if (store) {
      const currentState = store.getState()

      state.user.auth.isAuthorized = currentState.user.auth.isAuthorized
      state.user.profile.error = currentState.user.profile.error
      state.user.profile.info = currentState.user.profile.info
      state.user.profile.favouriteAds = currentState.user.profile.favouriteAds
      state.user.profile.savedFilters = currentState.user.profile.savedFilters
      state.user.profile.favouriteProfiles = currentState.user.profile.favouriteProfiles
      state.user.listSidebar = currentState.user.listSidebar

      state.common.transitions = currentState.common.transitions
      state.common.router = currentState.common.router
      state.common.is404 = !isPageExists
      state.common.userTypes = currentState.common.userTypes
      state.common.selectableUserTypes = currentState.common.selectableUserTypes
      state.common.isMobileDevice = currentState.common.isMobileDevice
      state.common.pageMetaTags = currentState.common.pageMetaTags
      state.common.pageMetaTagsSingle = currentState.common.pageMetaTagsSingle

      state.groups = currentState.groups

      state.categories = currentState.categories

      if (isPageExists) {
        state.ads.list = currentState.ads.list
        state.ads.pagination = currentState.ads.pagination
        state.ads.brands = currentState.ads.brands
        state.ads.products = currentState.ads.products
        state.ads.equipment = currentState.ads.equipment
        state.ads.services = currentState.ads.services
        state.ads.serviceTypes = currentState.ads.serviceTypes
        state.ads.sms = currentState.ads.sms
      }

      state.geoObjects.countries = currentState.geoObjects.countries
      state.geoObjects.regions = currentState.geoObjects.regions
      state.geoObjects.cities = currentState.geoObjects.cities

      state.form = currentState.form

      state.cart = currentState.cart
      state.orders = currentState.orders
    }
    return state
  }

  render () {
    const { categoryTitle, isPageExists, formStyle, isMobileDevice } = this.props

    console.log('isMobileDevice', isMobileDevice)

    const filterValues = {}

    return isPageExists
      ? <Page className='main-content-extended'>
        {!isMobileDevice
          ? (
            <AdsHeaderExtended
              filterValues={filterValues}
              formStyle={formStyle}
            />
          ) :
          <CategoryHorizontalMenuMobile />
        }
        
        <AdsList
          layoutStyle='no-sidebar'
          noAdsMessage={config.pages.category.noAdsMessage}
          filterValues={filterValues}
          formStyle={formStyle}
          title={`OGLASI ${categoryTitle} - IZBOR IZ PONUDE`}
        />
        <CategoryPagination />
        {isMobileDevice ? <PanelMobile filterValues={filterValues} /> : null}
      </Page>
      : <Page404 />
  }
}

const mapStateToProps = (state) => ({
  isMobileDevice: getIsMobileDevice(state)
})

OglasiCategoryPage.propTypes = {
  categoryTitle: PropTypes.string,
  formStyle: PropTypes.string.isRequired,
  isPageExists: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.string.isRequired
}

OglasiCategoryPage.defaultProps = {
  isPageExists: false
}

export default connect(mapStateToProps)(OglasiCategoryPage)
