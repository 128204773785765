import { BaseApi } from 'Core/api/BaseApi'
import { TypesHelper } from 'SRC/utils'

export class Api extends BaseApi {
  fetchServicesByCategory = async category => {
    const defaultResult = []
    if (TypesHelper.isPositiveNumber(category)) {
      try {
        const {body} = await Api.post('/service/listByCategory', {category})
        if (!body.error && Array.isArray(body.services)) {
          return body.services
        } else {
          return defaultResult
        }
      } catch (e) {
        return defaultResult
      }
    }
    return defaultResult
  }

  fetchServices = async (params) => {
    const defaultResult = []
    try {
      const {body} = await Api.post('/service/list', params)
      if (!body.error && Array.isArray(body.services)) {
        return body.services
      } else {
        return defaultResult
      }
    } catch (e) {
      return defaultResult
    }
  }
}
